import * as React from "react";
import Graph from "../../assets/icons/ContactEmail.svg";


import Icon from "./Icon";



const ScheduleIcon = () => {
    var smallStyle = { 
    };

    return (
        <React.Fragment>
            <Icon icon={Graph} text="Subscriptions" id="Subscriptions" style={smallStyle}/>
    </React.Fragment>
            );
};

            export default ScheduleIcon;


