import * as React from "react";
import { connect } from "react-redux";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import {
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle
} from "reactstrap";
import { clearEditScheduleDetails } from "../../actions/editScheduleDetails";

import { IStore } from "../../models/IStore";
import paths  from "../../constants";
import Text from "../atoms/Text";
import ScheduleIcon from "../molecules/ScheduleIcon";

export interface IScheduleDropDownMenuProps extends RouteComponentProps {
    onHandleClearEditSchedule: () => void;
    //onHandleClearEditUser: () => void;
    //isKpiScheduleistrator: boolean;
    //isSuperScheduleistrator: boolean;
}

class ScheduleDropDownMenu extends React.Component<IScheduleDropDownMenuProps> {
    public state: any = {
        dropdownOpen: false
    };
    constructor(props: IScheduleDropDownMenuProps) {
        super(props);

        this.toggle = this.toggle.bind(this);
        this.state = {
            dropdownOpen: false
        };
    }

    public toggle() {
        this.setState({
            dropdownOpen: !this.state.dropdownOpen
        });
    }

    public render() {
        return (
            <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggle}>
                <DropdownToggle
                    tag="span"
                    onClick={this.toggle}
                    data-toggle="dropdown"
                    aria-expanded={this.state.dropdownOpen}
                    style={{ cursor: "pointer" }}
                >
                    <ScheduleIcon />
                    <Text style={{color: "#FFF", paddingLeft: "8px"  }}> Subscriptions</Text>
                </DropdownToggle>
                <DropdownMenu> 
                    <DropdownItem>
                        <Link
                            to={paths.addScheduleWizard}
                            // tslint:disable-next-line:jsx-no-lambda
                            onClick={async (e: any) => {
                                e.preventDefault();
                                this.props.onHandleClearEditSchedule();
                                this.props.history.push(paths.addScheduleWizard);
                            }}
                        >
                            New Subscription Wizard
            </Link>
                    </DropdownItem>
                    <DropdownItem>
                        <Link to={paths.searchSchedules}>Search/Edit Subscriptions</Link>
                    </DropdownItem>
                    {/*<DropdownItem>*/}
                    {/*    <Link to={paths.emailReports + "1/23"}>List of Email's Reports</Link>*/}
                    {/*</DropdownItem>*/}
                </DropdownMenu>
            </Dropdown>
        );
    }
}
const mapStateToProps = (state: IStore) => {
    return {         
    };
};
const mapDispatchToProps = (dispatch: any) => ({
    // tslint:disable-next-line:object-literal-sort-keys
        onHandleClearEditSchedule: () => {
        dispatch(clearEditScheduleDetails());
    } 
});

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(ScheduleDropDownMenu)
);
